import { BaseDataStatus } from "./base-data";

export interface ManualDatabaseReference {
  originDatabase: string;
  co2Factor: number;
}

export interface ExternalDatabaseReference {
  co2eDatabaseEntryId: string;
  scaleFactor: number;
}

export interface Co2TypeVersionInput {
  id?: string;
  validFrom: string;
  validUntil: string;
  locked: boolean;
  kgEquivalent: number;
  documentation: string;
  externalDatabaseReference?: ExternalDatabaseReference;
  manualDatabaseReference?: ManualDatabaseReference;
}

export interface Co2eDatabase {
  uuid: string;
  name: string;
}

export interface ExternalCo2eDatabaseEntry {
  id: string;
  co2eDatabase: Co2eDatabase;
  geography: string;
  displayName: string;
  co2Equivalent: number;
  unit: string;
  scaleFactor: number;
}

export interface Co2TypeVersionOutput {
  id: string;
  validFrom: string;
  validUntil: string;
  locked: boolean;
  co2Factor: number;
  kgEquivalent: number;
  originDatabase: string;
  documentation: string;
  co2eDatabaseEntry?: ExternalCo2eDatabaseEntry;
}

export enum QuantityType {
  DEFAULT = "DEFAULT",
  DISTANCE_ROAD = "DISTANCE_ROAD",
  DISTANCE_RAIL = "DISTANCE_RAIL",
  DISTANCE_SEA = "DISTANCE_SEA",
  DISTANCE_AIR = "DISTANCE_AIR",
}

export enum Co2TypeActivationStatus {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
}

export enum Co2CategoryActivationStatus {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
}

export interface ScopeInfo {
  id: string;
  name: string;
  description: string;
}

export interface UICategoryInfo {
  id: string;
  name: string;
}

export interface LocalizedText {
  de: string;
  en: string;
}

export interface Co2Category {
  id: string;
  name: LocalizedText;
  scope: ScopeInfo;
  createdAt: number;
  uiCategory: UICategoryInfo;
  activationStatus: Co2CategoryActivationStatus;
}

export interface Co2CategorySearchModel {
  id: string;
  name: string;
  scope: string;
  createdAt: number;
  activationStatus: Co2CategoryActivationStatus;
}

export interface CategoryInfo {
  id: string;
  name: string;
  activationStatus: Co2CategoryActivationStatus;
  ghgScope: ScopeInfo;
}

// Base Co2Type interface
export interface BaseCo2Type {
  id: string;
  name: LocalizedText;
  companyName: string;
  companyId: string;
  geography: string;
  quantityType: QuantityType;
  scope: ScopeInfo;
  category: CategoryInfo;
  unit: {
    id: string;
    name: string;
    description?: string;
    symbols: string[];
  };
  activationStatus: Co2TypeActivationStatus;
  status: BaseDataStatus;
}

// Input Co2Type
export interface Co2Type extends BaseCo2Type {
  versions: Co2TypeVersionInput[];
}

// Output Co2Type
export interface Co2TypeOutput extends BaseCo2Type {
  versions: Co2TypeVersionOutput[];
}

export interface Co2TypeSearch {
  id: string;
  name: string;
  categoryId: string;
  categoryName: string;
  categoryActivationStatus: Co2CategoryActivationStatus;
  companyId: string;
  companyName: string;
  scope: string;
  unit: string;
  versionsCount: number;
  currentCo2Factor: number;
  currentKgEquivalent?: number;
  createdAt: number;
  activationStatus: Co2TypeActivationStatus;
  quantityType?: QuantityType;
  geography?: string;
  // TODO: Is this really the Co2TypeVersionInput? Shouldn't it be Output?
  versions?: Co2TypeVersionInput[];
  externalIds?: string[];
  status?: BaseDataStatus;
}

export interface Co2TypeUsage {
  company: {
    id: string;
    name: string;
  };
  taggedCount: number; // number of times the co2 type is tagged
  unitBookingCount: number; // number of unit bookings connected to the co2 type
  pendingUnitBookingCount: number;
  supplierCount: number; // number of suppliers having at least 1 transport route which uses the co2 type
  financialModelCount: number; // number of financial models having at least 1 financial model item which uses the co2 type
  commuterSurveyCount: number; // number of commuter surveys connected to the co2 type
}
